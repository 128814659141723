<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="350">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create New State
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[0].selected"
                        :items="form[0].country"
                        :rules="form[0].rule"
                        item-text="name"
                        item-value="CountryCode"
                        label="Select Country"
                        outlined
                    ></v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form[1].statename"
                        :rules="form[1].rule"
                        label="State Name"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {country: [], rule: [], selected: '' },
                {statename: '', rule: [] },
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        this.$watch(() => this.form[0].country, this.onRuleChange);
        this.$watch(() => this.form[1].statename, this.onRuleChange);
    },

    methods: {
        async getAllCountry(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listCountry", {search: ''});
                let dt = response.data.record;
                this.form[0].country.push({name: "Select Country", CountryCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;

                        this.form[0].country.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.CountryCode.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
        },

        async beforeCreateForm(flag){
            let check = await this.checkRef(this.form[0].countrycode);
            
            this.form[0].rule = [
                v => !!v || 'Country is required',
            ];

            this.form[1].rule = [
                v => !!v || 'State is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            /*
            this.$nextTick(() => {
            if (this.$refs['ct'])
            this.$refs.ct.reset();
            });
            */
            this.getAllCountry();
            this.form[0].selected = '';
            this.form[1].statename = '';
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>