<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>State</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="offset-md-6 col-12 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Country"
                    label="Country"
                    v-model="country.selected"
                    @change="execFilter('country')"
                    :items="country.items"
                    item-text="name"
                    item-value="CountryCode"
                    :rules="country.rule"
                    :loading="country.loader"
                    :disabled="country.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('state_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create State
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span v-if="componentLoader" class="text-body-2">View {{items[0].row}} - {{items[items.length - 1].row}} of {{total_records}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="CreatedDateTime"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CountryCode}}</td>
                            <td>{{props.item.CountryName}}</td>
                            <td>{{props.item.StateCode}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('state_update')">
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.StateName}}</a>
                                </template>
                                <span>Update State</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.StateName}}</a>
                                </template>
                                <span>View State</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.UpdatedDateTime}}</td>
                            <!--
                            <td v-if="validateAccess('state_delete')">
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <span>
                                    <a v-on="on" id="custom-hover" @click.prevent="viewMore(props.item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                                </span>
                                </template>
                                <span>Remove State</span>
                                </v-tooltip>
                            </td>
                            -->
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :readonly="ma.readonly" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateState';
import edit from '.././modals/UpdateState';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'State',
    title: 'State',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Administration',
                disabled: true,
                href: '#',
                },

                {
                text: 'Location',
                disabled: true,
                href: '#',
                },

                {
                text: 'State',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Country Code', value: 'CountryCode', width: '3%' },
                { text: 'Country Name', value: 'CountryName', width: '3%' },
                { text: 'State Code', value: 'StateCode', width: '3%' },
                { text: 'State Name', value: 'StateName', width: '15%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'UpdatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            total_records: null,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            country: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            componentLoader: false,
        }
    },

    components: {create, edit, alert, alertbox2},

    async created(){
        this.getCountryFull();
        this.getState();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getState();
        }
        },
    },

    methods: {
        async getState(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("listState", {search: this.country.selected});
                let response2 = await this.$store.dispatch("listCountry", {search: ''});

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.record.length;

                for(var i=0; i<dt.length; i++){
                    let it = response2.data.record.find(item => item.CountryCode == dt[i].CountryCode);
                    dt[i].row = i + 1;
                    dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = dt[i].UpdatedDateTime === null ? "N/A" : moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].CountryName = it.CountryName;
                    //replace(/\s/g,'') remove any literal whitespace
                }

                this.total_records = dt.length;
                var size = 100; var arrayOfArrays = [];
                for (var i=0; i<dt.length; i+=size) {
                    arrayOfArrays.push(dt.slice(i,i+size));
                }

                this.items = arrayOfArrays[this.page - 1];
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                this.componentLoader = true;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getCountryFull(){
            let list = [];
            this.country.items = [];
            try{
                this.country.loader = true;
                this.country.disable = true;
                let response = await this.$store.dispatch("listCountryAll");
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.country.items.push({name: "All Country", CountryCode: ""});
                    this.country.itemsFull.push({name: "All Country", CountryCode: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;
                        this.country.items.push({name: dt[i].name, CountryCode: dt[i].CountryCode});
                        this.country.itemsFull.push({name: dt[i].name, CountryCode: dt[i].CountryCode});
                    }
                }

                this.country.selected = this.country.items[0].CountryCode;

                this.country.loader = false;
                this.country.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addState(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {countrycode: v[0].selected, statename: v[1].statename};
                let response = await this.$store.dispatch("createState", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.getState();
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: State Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This state is already created`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async stateRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteState", {statecode: this.ma.modalInfo.StateCode})
                if (response.data.code === 'AP000'){
                        await this.getState();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: State has been removed!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to remove. State is currently being used!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async stateUpdate(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateState", {countrycode: v[0].selected, statecode: v[1].statecode, statename: v[2].statename})
                if (response.data.code === 'AP000'){
                        await this.getState();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: State has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update. State is currently being used!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addState(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.stateUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.stateRemove();
                }
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.stateRemove();
        },

        async execFilter(type){
            if (type == 'country'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getState();
          }, 800);
        }
    }
}
</script>